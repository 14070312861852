import React from 'react'
import { RiSearch2Line } from "react-icons/ri";
import { NavLink } from "react-router-dom";


const Servicios = () => {
    return (
        <div className='flex flex-col items-center justify-center gap-8 p-8 py-16 bg-gray-200'>
            <h1 className='text-4xl font-semibold tracking-normal text-gray-800'>NUESTROS SERVICIOS</h1>
            <div className='grid items-center gap-12 text-xl text-gray-500 grid-cols md:grid-cols-4 md:text-lg md:text-xl'>
                <NavLink to={'/servicios/domotica'} className='flex flex-col justify-center p-8 text-center transition-all duration-100 border-2 cursor-pointer hover:border-gray-500 hover:rounded-xl hover:shadow-2xl'>
                    <img src="videportero.png" className='cursor-pointer w-60' />
                    <h2 className='pt-4 cursor-pointer '>Porteros</h2>
                </NavLink>
                <div className='flex flex-col justify-center h-full px-2 pt-2 text-center transition-all duration-100 border-2 cursor-pointer hover:border-gray-500 hover:rounded-xl hover:shadow-2xl'>
                    <img src="grupopresion.png" className='w-64 ' />
                    <h2 className='pt-4 cursor-pointer'>Grupo de Presión</h2>
                </div>
                <div className='flex flex-col justify-center h-full px-4 pt-4 text-center transition-all duration-100 border-2 cursor-pointer hover:border-gray-500 hover:rounded-xl hover:shadow-2xl'>
                    <img src="puerta.png" className='w-60' />
                    <h2 className='cursor-pointer '>Automatismos</h2>
                </div>
                <NavLink to={'/servicios/domotica'} className='flex flex-col justify-center h-full pt-2 text-center transition-all duration-100 border-2 cursor-pointer hover:border-gray-500 hover:rounded-xl hover:shadow-2xl'>
                    <img src="camara.webp" className='self-center w-60' />
                    <h2 className='-mt-2 cursor-pointer'>Domótica</h2>
                </NavLink>

            </div>
        </div>
    )
}

export default Servicios