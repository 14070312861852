import React, { useEffect, useState } from "react";
import ScrollToTop from "../../shared/ScrollToTop";
import Header from "../../shared/Header";
import Footer from "../../shared/Footer";
import CookieConsent from "react-cookie-consent";
import { Link, useParams } from "react-router-dom";
import Mantenimiento from "../../shared/Mantenimiento";
import ServiciosComponent from "./ui/ServiciosComponent";
import { AutomatismosData } from "../../../data/Automatismos";
import { PresionData } from "../../../data/presion";
import { DomoticaData } from "../../../data/domotica";

const Fade = require("react-reveal/Fade");

const Servicios = () => {
  const [loading, setLoading] = useState(false);

  const { tipo, subtipo } = useParams();
  const url = subtipo ? `${tipo}/${subtipo}` : tipo;

  return (
    <div className="flex flex-col items-stretch min-h-screen ">
      <ScrollToTop />
      <Header />
      <CookieConsent
        location="bottom"
        buttonText="Aceptar Cookies"
        cookieName="myAwesomeCookieName2"
        style={{ background: "#2B373B", textAlign: "center" }}
        buttonStyle={{
          backgroundColor: "#1961F1",
          color: "#ffffff",
          fontSize: "18px",
        }}
        expires={150}
        declineButtonText="Rechazar"
        declineButtonClasses="bg-header hover:opacity-80 transition-all duration-200"
        buttonClasses="bg-header hover:opacity-80 transition-all duration-200"
        enableDeclineButton
      >
        Este sitio web utiliza cookies para la mejora de experiencia de usuario{" "}
        <Link to="/cookies" className="text-gray-500 cursor-pointer">
          Para mas información
        </Link>
      </CookieConsent>

      <div className="flex flex-col items-center justify-start flex-1 h-full pt-28 pb-24">
        {loading ? (
          <div className="flex flex-col items-center w-full pt-20">
            <div
              className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid 
                            border-current border-r-transparent align-[-0.125em] 
                            motion-reduce:animate-[spin_1.5s_linear_infinite]"
              role="status"
            >
              <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                Loading...
              </span>
            </div>
          </div>
        ) : (
          <div className="flex flex-col gap-4 2xl:mx-64">
            <Fade>
              {(() => {
                switch (url) {
                  case "abatibles/portales":
                  case "abatibles/garaje":
                  case "basculantes":
                  case "correderas":
                  case "enrollables":
                  case "seccionales":
                    return (
                      <ServiciosComponent url={url} data={AutomatismosData} />
                    );
                  case "agua":
                  case "achique":
                  case "filtro-agua":
                    return <ServiciosComponent url={url} data={PresionData} />;
                  case "porteros-gsm":
                  case "camaras":
                  case "llaves-nfc":
                    return <ServiciosComponent url={url} data={DomoticaData} />;
                  default:
                    return <Mantenimiento />;
                }
              })()}
            </Fade>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default Servicios;
