export const AutomatismosData = [
  {
    id: "abatibles/garaje",
    name: "PUERTAS ABATIBLES - GARAJE",
    description: [
      "Las puertas abatibles son el sistema de apertura más utilizado y tradicional. Casi todas las puertas que conocemos se abren de esta forma, con una hoja o dos hojas que están conectadas por bisagras a un marco fijado en la pared. Se caracterizan como función permitir el paso de un ambiente a otro, que se abren tanto para el interior como para el exterior.",
      "En lo que a diseño y versatilidad se refiere, se trata de un sistema que permite infinidad de posibilidades de diseño, colores y acabados, así como diversos equipamientos opcionales.",
    ],
    img: [
      {
        desc: "Modelo 1",
        src: "/images/automatismos/puertaabatible01.png",
        files: [
          { src: "/images/automatismos/puertaabatible01.png", type: "img" },
          { src: "/images/automatismos/videos/abatible01.mp4", type: "mp4" },
        ],
      },
      {
        desc: "Modelo 2",
        src: "/images/automatismos/puertaabatible02.png",
        files: [
          { src: "/images/automatismos/puertaabatible02.png", type: "img" },
          { src: "/images/automatismos/videos/abatible02.mp4", type: "mp4" },
        ],
      },
      {
        desc: "Modelo 3",
        src: "/images/automatismos/puertaabatible03.png",
        files: [
          { src: "/images/automatismos/puertaabatible03.png", type: "img" },
          { src: "/images/automatismos/videos/abatible03.mp4", type: "mp4" },
        ],
      },
    ],
  },
  {
    id: "abatibles/portales",
    name: "PUERTAS ABATIBLES - PORTALES",
    description: [
      "Las puertas abatibles son el sistema de apertura más utilizado y tradicional.",
      "Casi todas las puertas que conocemos se abren de esta forma, con una hoja o dos hojas que están conectadas por bisagras a un marco fijado en la pared.",
      "Se caracterizan como función permitir el paso de un ambiente a otro, que se abren tanto para el interior como para el exterior.",
      "En lo que a diseño y versatilidad se refiere, se trata de un sistema que permite infinidad de posibilidades de diseño, colores y acabados, así como diversos equipamientos opcionales.",
    ],
    img: [
      {
        desc: "Modelo 1",
        src: "/images/automatismos/portal01.png",
        files: [
          { src: "/images/automatismos/portal01.png", type: "img" },
          {
            src: "/images/automatismos/videos/abatiblesportal01.mp4",
            type: "mp4",
          },
        ],
      },
      {
        desc: "",
        src: "",
        files: [],
      },
    ],
  },
  {
    id: "basculantes",
    name: "PUERTAS BASCULANTES",
    description: [
      "Las puertas basculantes son uno de los sistemas de puertas más utilizados.",
      "Son un tipo de puerta muy empleada en las puertas de garaje. En este caso, las puertas pueden estar formadas por una hoja o por 2 hojas ( puerta preleva), que se pliegan en sentido vertical y ascienden hacia el techo.",
      "Es muy habitual instalar este tipo de puertas automáticas en garajes individuales, pero son especialmente frecuentes en garajes comunitarios. ",
      "Lo más frecuente es encontrarnos con puertas basculantes preleva, pudiendo estar automatizadas o de apertura manual sin motor. Son puertas económicas que generalmente se fabrican en aluminio, material que aporta gran ligereza y resistencia, además de que soporta muy bien las inclemencias meteorológicas. ",
      "Las puertas basculantes aprovechan al máximo el espacio, sin necesidad de espacio extra para la apertura o deslizamiento. Se pliegan sobre sí mismas hacia el techo y el motor puede colocarse en el techo o en un lateral, por lo que tampoco ocupa espacio.",
    ],
    img: [
      {
        desc: "Modelo 1",
        src: "/images/automatismos/basculante01.png",
        files: [
          { src: "/images/automatismos/basculante01.png", type: "img" },
          { src: "/images/automatismos/videos/basculante01.mp4", type: "mp4" },
        ],
      },
      {
        desc: "Modelo 2",
        src: "/images/automatismos/basculante02.png",
        files: [
          { src: "/images/automatismos/basculante02.png", type: "img" },
          { src: "/images/automatismos/videos/basculante02.mp4", type: "mp4" },
        ],
      },
      {
        desc: "Modelo 2",
        src: "/images/automatismos/basculante03.png",
        files: [
          { src: "/images/automatismos/basculante03.png", type: "img" },
          { src: "/images/automatismos/videos/basculante03.mp4", type: "mp4" },
        ],
      },
    ],
  },
  {
    id: "correderas",
    name: "PUERTAS CORREDERAS",
    description: [
      "Las puertas correderas son uno de los sistemas de puertas más utilizados. Esto se debe a su sencillo funcionamiento.",
      "Dotadas de poleas superiores de teflón y ruedes inferiores de rodamiento se desplazan de forma lateral hasta quedar ocultas tras paredes o ballas. Esta sencillez de movimiento y el mínimo esfuerzo que necesitan para moverse, hacen que los automatismos destinados a ellas sean económicos y eficaces.",
      "En lo que a diseño y versatilidad se refiere, se trata de un sistema que permite infinidad de posibilidades de diseño, colores y acabados, así como diversos equipamientos opcionales.",
    ],
    img: [
      {
        desc: "Modelo 1",
        src: "/images/automatismos/corredera01.png",
        files: [
          { src: "/images/automatismos/corredera01.png", type: "img" },
          { src: "/images/automatismos/videos/corredera01.mp4", type: "mp4" },
        ],
      },
      {
        desc: "Modelo 2",
        src: "/images/automatismos/corredera02.png",
        files: [
          { src: "/images/automatismos/corredera02.png", type: "img" },
          { src: "/images/automatismos/videos/corredera02.mp4", type: "mp4" },
        ],
      },
      {
        desc: "Modelo 3",
        src: "/images/automatismos/corredera03.png",
        files: [
          { src: "/images/automatismos/corredera03.png", type: "img" },
          { src: "/images/automatismos/videos/corredera03.mp4", type: "mp4" },
        ],
      },
    ],
  },
  {
    id: "enrollables",
    name: "PUERTAS ENROLLABLES",
    description: [
      "Las puertas enrollables automáticas son sistemas de puertas muy utilizados en los ámbitos industrial, comercio y residencial. Pueden instalarse en un sinfín de lugares; algunos de los más comunes son: Garajes, locales comerciales como tiendas, joyerías, restaurantes, farmacias, muelles de carga en almacenes, interiores y exteriores de naves industriales.",
      "ㅤ",
      "Su uso tan extendido es debido a sus múltiples ventajas:",
      "ㅤAprovechan muy bien el espacio",
      "ㅤAl enrollarse sobre sí mismas y quedarse en la parte superior,",
      "ㅤSon muy seguras, duraderas y resistentes. ",
      "ㅤ",
      "Todas cuentan con sistemas de auto retención para garantizar un paso seguro. Se pueden fabricar de diversas formas y materiales. Los más habituales son el acero y el aluminio, siendo ésta última, la solución que ofrece mejor rendimiento en duración del material, calidad en los acabados y diseño. Además, si tienes un local comercial y quieres que se vea el escaparate para atraer clientes, aunque estés cerrado, se pueden fabricar a modo de reja.",
      "Son totalmente personalizables.",
    ],
    img: [
      {
        desc: "Bomberos de Huelva",
        src: "/images/automatismos/enrollable01.png",
        files: [
          { src: "/images/automatismos/enrollable01.png", type: "img" },
          { src: "/images/automatismos/videos/enrollable01.mp4", type: "mp4" },
        ],
      },
      {
        desc: "Bomberos de Huelva",
        src: "/images/automatismos/enrollable02.png",
        files: [
          { src: "/images/automatismos/enrollable02.png", type: "img" },
          { src: "/images/automatismos/videos/enrollable02.mp4", type: "mp4" },
        ],
      },
    ],
  },
  {
    id: "seccionales",
    name: "PUERTAS SECCIONALES",
    description: [
      "Las puertas secciónales son una clase de puerta en el que su sistema de apertura y cierre es de manera vertical.",
      "Así cuando este se activa y se abre, queda paralelamente al techo.",
      "Además, su apertura vertical, permite aproximar el coche totalmente a la puerta.",
      "Esta característica también favorece a los garajes con poco fondo en los que el coche queda muy próximo a la puerta.",
      "La maniobra de apertura y cierre no invade ningún espacio adicional.",
      "Son muy seguras, duraderas y resistentes.",
      "Todas cuentan con sistemas de auto retención para garantizar un paso seguro.",
      "En lo que a diseño y versatilidad se refiere, se trata de un sistema que permite infinidad de posibilidades de diseño, colores y acabados, así como diversos equipamientos opcionales que hacen de este sistema, uno de los más adecuados para el uso en garajes.",
    ],
    img: [
      {
        desc: "Modelo 1",
        src: "/images/automatismos/seccionale01.png",
        files: [
          { src: "/images/automatismos/seccionale01.png", type: "img" },
          { src: "/images/automatismos/videos/seccionales01.mp4", type: "mp4" },
        ],
      },
      {
        desc: "",
        src: "/images/automatismos/seccionale01.png",
        files: [
          { src: "/images/automatismos/seccionale01.png", type: "img" },
          { src: "/images/automatismos/videos/seccionales01.mp4", type: "mp4" },
        ],
      },
    ],
  },
];
