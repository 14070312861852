import React from "react";
import { MantenimientosData } from "../../../../data/MantenimientosData";
import { NavLink } from "react-router-dom";

const MantenimientosComponent = ({
  url,
  data,
}: {
  url: string;
  data: typeof MantenimientosData;
}) => {
  return (
    <div>
      {data.map(
        (item) =>
          item.id &&
          item.id === url && (
            <div className="container pt-28">
              <h1 className="text-4xl font-bold text-center 2xl:text-5xl ">
                {item.name}
              </h1>
              <div className="my-10 ">
                {item.description.map((desc) => (
                  <p className="px-6 py-2 text-xl text-gray-700">{desc}</p>
                ))}
              </div>
              <div className="flex flex-col items-center w-full gap-4 md:justify-center md:flex-row">
                <div className="">
                  <NavLink
                    to={item.servicio}
                    className="w-3/5 px-8 py-2 mt-8 text-2xl text-center text-white transition-all duration-200 bg-green-700 shadow-xl md:w-2/5 rounded-xl hover:opacity-80 hover:scale-110"
                  >
                    Ver servicio
                  </NavLink>
                </div>
                <div className="mt-4 md:mt-0">
                  <NavLink
                    to={"/contact"}
                    className="w-3/5 px-8 py-2 mt-8 text-2xl text-center text-white transition-all duration-200 shadow-xl bg-secondary md:w-2/5 rounded-xl hover:opacity-80 hover:scale-110"
                  >
                    Contactanos
                  </NavLink>
                </div>
              </div>
            </div>
          )
      )}
    </div>
  );
};

export default MantenimientosComponent;
