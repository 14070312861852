export const PresionData = [
  {
    id: "agua",
    name: "GRUPO DE PRESIÓN - AGUA POTABLE",
    description: [
      "Los grupos de presión de agua son conjuntos diseñados para el suministro automático de agua a presión de forma regular, continua y automática en redes de abastecimiento donde la presión es irregular o insuficiente.",
      "Están formados por una bomba circuladora, que es la encargada de impulsar el agua, y un sistema de control, ya sea mecánico o electrónico, para el arranque y detención automática de la bomba. Están destinados, principalmente, al suministro automático de agua en viviendas unifamiliares, pequeños edificios, sistemas de riegos por aspersión para superficies ajardinadas o pequeñas aplicaciones industriales.",
    ],
    img: [
      {
        desc: "Estandar",
        src: "/images/presion/gpresion-agua01.png",
        files: [
          { src: "/images/presion/gpresion-agua01.png", type: "img" },
          { src: "/images/presion/gpresion-agua02.jpeg", type: "img" },
        ],
      },
      {
        desc: "Con variador de frecuencia",
        src: "/images/presion/gpresion-agua03.jpeg",
        files: [
          { src: "/images/presion/gpresion-agua03.jpeg", type: "img" },
          { src: "/images/presion/gpresion-agua04.jpeg", type: "img" },
        ],
      },
    ],
  },
  {
    id: "achique",
    name: "GRUPO DE PRESIÓN - BOMBAS DE ACHIQUE",
    description: [
      "Las bombas de achique son máquinas eléctricas que se utilizan principalmente para extraer agua. Este tipo de máquina suele estar formada por un motor que proporciona la energía suficiente para extraer el agua. Normalmente dispone de una carcasa que sirve para proteger dicho motor de los daños externos.",
      "Sus usos pueden ser múltiples, desde achicar agua en piscinas para vaciarlas, pozos, estanques y en aquellos lugares inundados como consecuencia de las lluvias intensas.",
      "La máquina suele extraer el agua por su base. Una rejilla impedirá el paso de partículas que puedan dañarlo. Esta agua será filtrada y transportada a través de un tubo o manguera.",
    ],
    img: [
      {
        desc: "Modelo 1",
        src: "/images/presion/gpresion-achique01.png",
        files: [{ src: "/images/presion/gpresion-achique01.png", type: "img" }],
      },
      {
        desc: "Modelo 2",
        src: "/images/presion/gpresion-achique02.jpeg",
        files: [
          { src: "/images/presion/gpresion-achique02.jpeg", type: "img" },
        ],
      },
      {
        desc: "Modelo 3",
        src: "/images/presion/gpresion-achique03.jpeg",
        files: [
          { src: "/images/presion/gpresion-achique03.jpeg", type: "img" },
        ],
      },
    ],
  },
  {
    id: "filtro-agua",
    name: "GRUPO DE PRESIÓN - FILTROS DE AGUA",
    description: [
      "Los filtros de agua son dispositivos o sistemas diseñados para purificar y filtrar el agua.",
      "Su objetivo principal es eliminar impurezas, sedimentos, bacterias y otros contaminantes presentes en el agua, mejorando así su calidad y seguridad para el consumo humano.",
      "Estos filtros pueden ser instalados en las líneas principales de suministro de agua de los edificios, en los puntos de uso o en ambos lugares, dependiendo de las necesidades y requerimientos específicos de los edificios. Algunos tipos comunes de filtros de agua para edificios incluyen filtros de sedimentos, filtros de carbón activado, filtros de ósmosis inversa y sistemas de desinfección UV.",
    ],
    img: [
      {
        desc: "Modelo 1",
        src: "/images/presion/gpresion-filtro01.jpeg",
        files: [{ src: "/images/presion/gpresion-filtro01.jpeg", type: "img" }],
      },
      {
        desc: "Modelo 2",
        src: "/images/presion/gpresion-filtro02.jpeg",
        files: [{ src: "/images/presion/gpresion-filtro02.jpeg", type: "img" }],
      },
      {
        desc: "Modelo 3",
        src: "/images/presion/gpresion-filtro03.jpeg",
        files: [{ src: "/images/presion/gpresion-filtro03.jpeg", type: "img" }],
      },
    ],
  },
];
