import React, { useEffect, useState } from "react";
import SwiperComponent from "./SwiperComponent";
import { url } from "inspector";
import path from "path";
import { RiPlayCircleFill } from "react-icons/ri";
import { AutomatismosData } from "../../../../data/Automatismos";

const Fade = require("react-reveal/Fade");
const createInitialSwiper = () => [{ src: null, type: null }];
const createInitialItem = () => {
  const items = [];
  for (let i = 0; i < 3; i++) {
    items.push({
      id: i,
      showPlay: false,
    });
  }
  return items;
};
const ServiciosComponent = ({
  url,
  data,
}: {
  url: string;
  data: typeof AutomatismosData;
}) => {
  const [viewSwiper, setViewSwiper] = useState(createInitialSwiper());
  const [viewItem, setViewItem] = useState(createInitialItem());
  const [itemShow, setItemShow] = useState(0);
  const handleViewSwiper = (obj: any, index: number) => {
    setViewSwiper(obj);
    setItemShow(index);
  };

  const handleViewItem = (index: number, showPlay = false) =>
    setViewItem([
      {
        id: index,
        showPlay: showPlay,
      },
    ]);

  useEffect(() => {
    setViewItem(createInitialItem());
    setViewSwiper(createInitialSwiper());
    // handleViewSwiper(data[viewItem[0].id].img, 0);
  }, [url]);

  return (
    <div>
      {data.map(
        (item) =>
          item.id &&
          item.id === url && (
            <div className="container">
              <h1 className="text-4xl font-bold text-center 2xl:text-5xl ">
                {item.name}
              </h1>

              <div className="my-10  flex flex-col lg:flex-row lg:h-[620px]">
                <div
                  className={`flex max-w-full flex-col  lg:w-9/12 2xl:w-4/6 `}
                >
                  {item.img && item.img[0].files.length > 0 ? (
                    <div>
                      <Fade left>
                        <h1 className="text-3xl font-semibold text-center text-gray-800 h-max-[600px]">
                          {item.img[itemShow].desc}
                        </h1>
                        <div className="grid h-full md:flex ">
                          <SwiperComponent
                            elements={viewSwiper}
                            element0={item.img[0]}
                          />
                        </div>
                      </Fade>
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div className="flex flex-col mt-10 md:flex-row lg:flex-col md:w-full lg:w-3/12 2xl:w-2/6 lg:mt-0 gap-y-4 2xl:px-20">
                  <Fade right>
                    {item.img &&
                      item.img.map((img, index) => (
                        <div
                          className="w-full mx-1 transition-all duration-100 rounded-md select-none lg:mx-0 bg-im active:scale-105 "
                          onMouseEnter={() => handleViewItem(index, true)}
                          onMouseLeave={() => handleViewItem(index, false)}
                          onClick={() => handleViewSwiper(img.files, index)}
                        >
                          {img.desc ? (
                            <div className="">
                              <div
                                style={{ backgroundImage: `url(${img.src})` }}
                                className="mx-auto w-2/4 md:w-full ease-in hover:bg-[length:500px]
                                                                                  hover:grayscale-[0.5] bg-center bg-[length:350px] h-44  transition-all duration-500
                                                                                  lg:mx-auto border-[3px] border-black rounded-2xl shadow-xl cursor-pointer"
                              >
                                <p
                                  className={` text-center mt-12 h-full text-3xl text-white  font-bold`}
                                >
                                  <span className="flex w-full place-content-center">
                                    <RiPlayCircleFill
                                      className={`${
                                        viewItem.find(
                                          (item) => item.id === index
                                        )?.showPlay
                                          ? "visible"
                                          : "invisible"
                                      }

                                                                                      transition-opacity ease-in duration-500 text-header text-6xl rounded-full bg-white`}
                                    />
                                  </span>
                                </p>
                              </div>
                              <p className="mt-1 text-xl font-bold text-center">
                                {img.desc}
                              </p>
                            </div>
                          ) : (
                            <div></div>
                          )}
                        </div>
                      ))}
                  </Fade>
                </div>
              </div>
              <div className="w-full mt-8 text-lg text-justify lg:mt-4">
                <div>
                  <h1 className="px-6 pb-2 text-3xl text-gray-800">
                    Descripción
                  </h1>
                  {item.description.map((desc) => (
                    <p className="px-6 text-gray-700 ">{desc}</p>
                  ))}
                </div>
              </div>
            </div>
          )
      )}
    </div>
  );
};

export default ServiciosComponent;
