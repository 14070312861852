export const MantenimientosData = [
  {
    id: "puertas-garaje",
    name: "MANTENIMIENTOS - PUERTAS DE GARAJE",
    description: [
      "En nuestra empresa, nos especializamos en brindar servicios profesionales de mantenimiento de puertas de garajes. Contamos con un equipo altamente capacitado y experimentado en el mantenimiento y reparación de diferentes tipos de puertas de garajes, ya sean seccionales, basculantes, enrollables, correderas, entre otras.",
      "Nuestro objetivo es asegurarnos de que las puertas de garajes de nuestros clientes funcionen de manera óptima y segura. Realizamos inspecciones periódicas para detectar posibles problemas o desgastes, y llevamos a cabo el mantenimiento preventivo necesario para evitar averías y prolongar la vida útil de las puertas.",
      "Además, ofrecemos servicios de reparación rápida y eficiente en caso de emergencias o averías inesperadas. Trabajamos con piezas de repuesto de alta calidad para garantizar la durabilidad y el rendimiento de las puertas de garajes.",
      "Si estás buscando una empresa confiable y profesional para el mantenimiento de tus puertas de garajes, no dudes en contactarnos. Estaremos encantados de brindarte nuestro servicio de calidad y atención personalizada.",
    ],
    servicio: "/servicios/automatismos/abatibles/garaje",
    img: {
      desc: "Modelo 1",
      src: "/mantenimientos/garajes.jpeg",
    },
  },
  {
    id: "gpresion",
    name: "MANTENIMIENTOS - GRUPOS DE PRESIÓN",
    description: [
      "En nuestra empresa, nos especializamos en ofrecer servicios de mantenimiento y reparación de grupos de presión de aguas. Contamos con un equipo de profesionales altamente capacitados y con amplia experiencia en el mantenimiento y funcionamiento eficiente de estos sistemas.",
      "Nuestro objetivo principal es garantizar que los grupos de presión de aguas de nuestros clientes funcionen de manera óptima y segura. Realizamos inspecciones periódicas y mantenimiento preventivo para asegurarnos de que todos los componentes del sistema estén en buen estado y funcionando correctamente. Esto incluye la revisión de las bombas, los tanques de presión, las válvulas y otros elementos clave.",
      "Trabajamos con equipos y repuestos de alta calidad para garantizar la durabilidad y el rendimiento de los grupos de presión de aguas. Nuestro objetivo es brindar un servicio de calidad, confiable y eficiente para satisfacer las necesidades de nuestros clientes.",
      "Si estás buscando una empresa profesional y confiable para el mantenimiento de tus grupos de presión de aguas, no dudes en contactarnos. Estaremos encantados de ofrecerte nuestro servicio y asesoramiento personalizado.",
    ],
    servicio: "/servicios/gpresion/agua",
    img: {
      desc: "Modelo 1",
      src: "/mantenimientos/garajes.jpeg",
    },
  },
  {
    id: "porteros",
    name: "MANTENIMIENTOS - PORTEROS GSM",
    description: [
      "En nuestra empresa, nos especializamos en brindar servicios de mantenimiento y reparación de porteros GSM. Contamos con un equipo de técnicos altamente capacitados y con experiencia en el mantenimiento y funcionamiento eficiente de estos sistemas de comunicación.",
      "Nuestro objetivo principal es garantizar que los porteros GSM de nuestros clientes funcionen de manera óptima y segura. Realizamos inspecciones periódicas y mantenimiento preventivo para asegurarnos de que todos los componentes del sistema estén en buen estado y funcionando correctamente. Esto incluye la revisión de los paneles de control, los dispositivos de audio y video, las antenas y otros elementos clave.",
      "Además, ofrecemos servicios de reparación rápida y eficiente en caso de averías o problemas inesperados. Contamos con amplio conocimiento en identificar y solucionar problemas comunes como fallas en la conexión, problemas de audio o video, entre otros.",
      "Trabajamos con equipos y repuestos de alta calidad para garantizar la durabilidad y el rendimiento de los porteros GSM. Nuestro objetivo es brindar un servicio confiable y eficiente para satisfacer las necesidades de nuestros clientes.",
      "Si estás buscando una empresa profesional y confiable para el mantenimiento de tus porteros GSM, no dudes en contactarnos. Estaremos encantados de ofrecerte nuestro servicio y asesoramiento personalizado.",
    ],
    servicio: "/servicios/domotica/porteros-gsm",

    img: {
      desc: "Modelo 1",
      src: "/mantenimientos/garajes.jpeg",
    },
  },
  {
    id: "bombas",
    name: "MANTENIMIENTOS - BOMBAS DE ACHIQUE",
    description: [
      "En nuestra empresa, nos especializamos en ofrecer servicios de mantenimiento y reparación de bombas de achique de agua. Contamos con un equipo de técnicos altamente capacitados y con experiencia en el mantenimiento y funcionamiento eficiente de estos sistemas.",
      "Nuestro objetivo principal es garantizar que las bombas de achique de agua de nuestros clientes funcionen de manera óptima y eficiente. Realizamos inspecciones periódicas y mantenimiento preventivo para asegurarnos de que todos los componentes del sistema estén en buen estado y funcionando correctamente. Esto incluye la revisión de las bombas, los motores, los interruptores de flotador, las tuberías y otros elementos clave.",
      "Además, ofrecemos servicios de reparación rápida y eficiente en caso de averías o problemas inesperados. Contamos con amplio conocimiento en identificar y solucionar problemas comunes como obstrucciones, fugas, problemas eléctricos, entre otros.",
      "Trabajamos con equipos y repuestos de alta calidad para garantizar la durabilidad y el rendimiento de las bombas de achique de agua. Nuestro objetivo es brindar un servicio confiable y eficiente para satisfacer las necesidades de nuestros clientes.",
      "Si estás buscando una empresa profesional y confiable para el mantenimiento de tus bombas de achique de agua, no dudes en contactarnos. Estaremos encantados de ofrecerte nuestro servicio y asesoramiento personalizado.",
    ],
    servicio: "/servicios/gpresion/achique",

    img: {
      desc: "Modelo 1",
      src: "/mantenimientos/garajes.jpeg",
    },
  },
];
