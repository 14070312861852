import React from 'react'
import { RiCheckboxBlankCircleFill } from "react-icons/ri";
import { NavLink } from "react-router-dom";

const Footer = () => {
    return (
        <footer className='p-8 bg-footer xl:py-16 xl:px-32'>
            <div className='flex items-center justify-between pb-8 border-b border-gray-500 xl:px-12 broder-w servirus'>
                <div className='w-1/6'>
                <NavLink to='/' className='text-3xl  relative  z-30 text-headertext  servirus tracking-wider	font-[550]	flex flex-row'>
                    <img src='/logoH.svg' className='w-[60px] mr-2 ' alt=''/> SERVIRUS <span className='text-headertext text-sm absolute top-9 left-[4.9rem] tracking-wider'>AUTOMATISMOS</span>
                </NavLink>
                </div>
                <div></div>
            </div>
            <div className='justify-center mt-8 xl:px-12 text-headertext'>
                <h3 className='text-lg font-bold text-gray-200'>Compañia</h3>
                <nav className='flex flex-col gap-8 pl-8 mt-2 md:flex-row md:pl-2 md:items-center md:gap-20 '>
                    <a href="/about" className='text-sm text-gray-300 hover:text-white transition-color'>Sobre Nosotros</a>

                    <a href="/servicios/automatismos/abatibles/garaje" className='text-sm text-gray-300 hover:text-white'>Servicios</a>

                    <a href="/contact" className='text-sm text-gray-300 hover:text-white'>Contacto</a>
                </nav>
            </div>
            <div className='w-full mt-10 text-sm text-center text-gray-400'>
                <p>© syncropdev 2023 - All Rights Reserved</p>
            </div>
        </footer>
    )
}

export default Footer