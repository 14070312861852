export const DomoticaData = [
  {
    id: "porteros-gsm",
    name: "DOMÓTICA - PORTEROS GSM",
    description: [
      "Los porteros GSM son sistemas de intercomunicación que utilizan la tecnología GSM (Global System for Mobile Communications) para permitir la comunicación entre una puerta de entrada y el usuario a través de llamadas telefónicas. ",
      "Estos porteros están diseñados para ser instalados en edificios residenciales o comerciales y permiten a los visitantes llamar a un número de teléfono predefinido para solicitar acceso a la propiedad. El propietario o residente puede recibir la llamada, hablar con el visitante y abrir la puerta de forma remota utilizando su teléfono móvil.      ",
      "En resumen, los porteros GSM son sistemas de intercomunicación que utilizan la red GSM para permitir la comunicación y el control de acceso remoto a través de llamadas telefónicas.",
    ],
    img: [
      {
        desc: "Modelo 1",
        src: "/images/domotica/domotica-portero01.jpeg",
        files: [
          { src: "/images/domotica/domotica-portero01.jpeg", type: "img" },
          { src: "/images/domotica/domotica-portero02.webp", type: "img" },
        ],
      },
      {
        desc: "Modelo 2",
        src: "/images/domotica/domotica-portero03.jpeg",
        files: [
          { src: "/images/domotica/domotica-portero03.jpeg", type: "img" },
          { src: "/images/domotica/domotica-portero04.webp", type: "img" },
        ],
      },
    ],
  },
  {
    id: "camaras",
    name: "DOMÓTICA - CÁMARAS",
    description: [
      "Las cámaras de vigilancia son dispositivos que se utilizan para capturar imágenes o videos de un área específica con el propósito de monitorearla y mejorar la seguridad.",
      "Estas cámaras pueden ser analógicas o digitales, y se pueden conectar a un sistema central de monitoreo o grabación.",
      "Las cámaras de vigilancia se utilizan en una variedad de entornos, como hogares, negocios, edificios públicos y calles, para prevenir el delito, supervisar el tráfico y garantizar la seguridad en general.",
    ],
    img: [
      {
        desc: "Modelo Industrial",
        src: "/images/domotica/domotica-camara-01.webp",
        files: [
          { src: "/images/domotica/domotica-camara-01.webp", type: "img" },
          { src: "/images/domotica/domotica-camara-02.avif", type: "img" },
          { src: "/images/domotica/domotica-camara-03.jpeg", type: "img" },
        ],
      },
      {
        desc: "Interior",
        src: "/images/domotica/domotica-camara-05.jpeg",
        files: [
          { src: "/images/domotica/domotica-camara-05.jpeg", type: "img" },
        ],
      },
    ],
  },
  {
    id: "llaves-nfc",
    name: "DOMÓTICA - LLAVES NFC",
    description: [
      "Las llaves NFC, también conocidas como tarjetas NFC, son dispositivos de comunicación inalámbrica que utilizan la tecnología NFC (Near Field Communication).",
      "Estas llaves son pequeñas etiquetas o tarjetas que contienen un chip NFC y se pueden programar para realizar diversas funciones cuando se acercan a un lector NFC. ",
      "Algunas de las aplicaciones comunes de las llaves NFC incluyen el acceso a edificios, el pago sin contacto, la transferencia de datos entre dispositivos y la automatización del hogar.",
    ],
    img: [
      {
        desc: "Modelo 1",
        src: "/images/domotica/domotica-llave-01.webp",
        files: [
          { src: "/images/domotica/domotica-llave-01.webp", type: "img" },
        ],
      },
      {
        desc: "Modelo 2",
        src: "/images/domotica/domotica-llave-02.jpeg",
        files: [
          { src: "/images/domotica/domotica-llave-02.jpeg", type: "img" },
        ],
      },
    ],
  },
];
